import lottie from 'lottie-web';
import { tns } from 'tiny-slider';

((fn) => {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
})(() => {
    const contentSlider = document.querySelector('.slider');
    if (contentSlider) {
        tns({
            container: '.slider',
            items: 1,
            nav: false,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            animateDelay: 1300,
        });
    }

    /*- ANIMATE -*/

    const res2dppx = window.matchMedia('(min-resolution: 2dppx)').matches;
    const isDesktop = window.matchMedia('(min-width: 600px)').matches;

    const lotties = JSON.parse(document.querySelector('#lotties').innerHTML);

    const animData = {
        'lottie-header': {
            container: document.getElementById('lottie-header'),
            renderer: 'svg',
            loop: true,
            autoplay: !isDesktop,
            path: lotties.header[res2dppx ? '@2x' : '@1x'],
            initialSegment: [120, 450],
        },
        'lottie-optimized_forever': {
            container: document.getElementById('lottie-optimized_forever'),
            renderer: 'svg',
            loop: true,
            autoplay: !isDesktop,
            path: lotties.optimized_forever[res2dppx ? '@2x' : '@1x'],
        },
        'lottie-integrations': {
            container: document.getElementById('lottie-integrations'),
            renderer: 'svg',
            loop: true,
            autoplay: !isDesktop,
            path: lotties.integrations[res2dppx ? '@2x' : '@1x'],
        },
        'lottie-email_creator': {
            container: document.getElementById('lottie-email_creator'),
            renderer: 'svg',
            loop: true,
            autoplay: !isDesktop,
            path: lotties.email_creator[res2dppx ? '@2x' : '@1x'],
        },
    };

    const anim = {};
    anim['lottie-header'] = lottie.loadAnimation(animData['lottie-header']);
    anim['lottie-optimized_forever'] = lottie.loadAnimation(animData['lottie-optimized_forever']);
    anim['lottie-integrations'] = lottie.loadAnimation(animData['lottie-integrations']);
    anim['lottie-email_creator'] = lottie.loadAnimation(animData['lottie-email_creator']);

    Object.keys(anim).forEach((a) => {
        anim[a].addEventListener('loaded_images', () => {
            anim[a].play();
        });
    });
});
